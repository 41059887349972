<template>
  <div>
    <div class="header">
      <van-nav-bar :title="info.title" left-arrow @click-left="$router.go(-1)">
      </van-nav-bar>
    </div>
    <div class="maincontent">
      <!-- 轮播图 -->
      <div class="swipe">
        <van-swipe class="first-swipe" :autoplay="3000">
          <van-swipe-item>
            <img :src="require('../../assets/img/swipe.png')" />
          </van-swipe-item>
          <van-swipe-item>
            <img :src="require('../../assets/img/swipe.png')" />
          </van-swipe-item>
          <van-swipe-item>
            <img :src="require('../../assets/img/swipe.png')" />
          </van-swipe-item>
          <template #indicator>
            <span></span>
          </template>
        </van-swipe>
      </div>
      <van-row class="agreement">
        <p v-html="info.content"></p>
      </van-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: {}
    }
  },
  created() {
    this.getinfo()
  },
  methods: {
    async getinfo() {
      const { data } = await this.$http.get(
        '/home/fund/descdetail/id/' + this.$route.params.id
      )
      if (data) {
        if (data.code === 200) {
          this.info = data.data
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.maincontent {
  padding: 50px 0 0 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  height: 100vh;
  // 轮播图
  .first-swipe {
    height: 11rem;
    .van-swipe-item img {
      width: 94%;
    }
  }
  .agreement {
    width: 100%;
    color: #000;
    overflow: hidden;
    p {
      text-indent: 1.54667rem;
      text-align: left;
      margin-left: 0.66667rem;
      margin-top: 1.06667rem;
    }
  }
}
</style>
